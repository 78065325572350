<template>
    <img :src="imageSrc" alt="" @error="onImageError">
</template>

<script>
export default {
    name: "imageView",
    props:{
        src: {
            required: true,
            validator: prop => typeof prop === 'string' || prop === null
        },
    },
    data(){
        return{
            imageSrc: null,
        }
    },
    methods:{
        onImageError(event){
          // no_img파일 > 비디오 썸네일로 수정했는데 이미지 타입이면서 오류가 발생할 경우는 없는건가..?
          // event.target.src = require('@/assets/img/noimage.jpg');
          event.target.src = require('@/assets/img/common/video_thumb.png');
        }
    },
    created() {
        if(this.src){
            this.imageSrc = this.src;
        }else{
          // this.imageSrc = require('@/assets/img/noimage.jpg');
          event.target.src = require('@/assets/img/common/video_thumb.png');
        }
    },
    updated() {
        if(this.src){
            this.imageSrc = this.src;
        }else{
            // this.imageSrc = require('@/assets/img/noimage.jpg');
            event.target.src = require('@/assets/img/common/video_thumb.png');
        }
    }
}
</script>

<style scoped>

</style>