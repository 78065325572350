<template>
    <div :class="paginationClass">
        <slot name="count"></slot>
        <slot name="table" :currentPageData="currentPageData"></slot>
        <!-- s: pagination_sec -->
        <div class="pagination_group clearFix" v-show="pageData.length > 0">
            <div class="button_area prev_area flL">
                <button type="button" class="prev first" @click="prevGroupPage" :disabled="currentPage === 0">prev2</button>
                <button type="button" class="prev" @click="prevPage" :disabled="currentPage === 0">prev</button>
            </div>
            <div class="num_area flL">
                <span role="button" v-for="(index) in (pageCount + 1)"
                      :key="'paginationIndex'+index"
                      v-show="Math.floor((index-1) / 5) === Math.floor(currentPage / 5)">
                    {{index}}
                </span>
<!--                <span role="button">2</span>
                <span role="button">3</span>
                <span role="button">4</span>
                <span role="button">5</span>
                <span role="button">6</span>
                <span role="button">7</span>
                <span role="button">8</span>
                <span role="button">9</span>
                <span role="button">10</span>-->
            </div>
            <div class="next_area button_area flL">
                <button type="button" class="next" @click="nextPage" :disabled="currentPage >= pageCount">next</button>
                <button type="button" class="next last" @click="nextGroupPage" :disabled="currentPage >= pageCount">next2</button>
            </div>
        </div>
        <!-- e: pagination_sec -->
    </div>
</template>

<script>
export default {
    name: "pagination",
    props: {
        paginationClass: '',
        pageData: {
            type: Array,
            default: () => {
                return []
            }
        },
        pageOption: {
            type: Object,
            default: () => {
                return {
                    currentPage: 0,
                    pageSize: 10
                }
            }
        },
    },
    computed:{
        pageCount() { return Math.floor((this.pageData.length - 1) / this.pageSize) },
        currentPageData() {
            let returnData = [];

            if (this.pageData) {
                const start = this.currentPage * this.pageSize;
                const end = start + this.pageSize;

                returnData = this.pageData.slice(start, end);
            }
            return returnData;
        },
    },
    data(){
        return{
            currentPage: this.pageOption.currentPage,
            pageSize: this.pageOption.pageSize,
            pageMaxLength: null,
        }
    },
    watch: {
        pageData: {
            immediate: true,
            deep: true,
        },
        isResetPage() {
            this.currentPage = 0;
        },
        currentPage(newValue) {
            this.$emit('onChangeFirstPage', newValue === 0);
        }
    },
    methods: {
        prevPage() {
            const prevPage = this.currentPage - 1;

            this.currentPage = prevPage < 0 ? 0 : prevPage;
            this.$emit('onPageChange');
        },
        nextPage() {
            const nextPage = this.currentPage + 1;

            this.currentPage = nextPage > this.pageCount ? this.pageCount : nextPage;
            this.$emit('onPageChange');
        },
        prevGroupPage() {
            const currentPage = this.currentPage - 5;
            const targetPage =  currentPage - (currentPage % 5);

            this.currentPage = targetPage < 0 ? 0 : targetPage;
            this.$emit('onPageChange');
        },
        nextGroupPage() {
            const currentPage = this.currentPage + 5;
            const targetPage =  currentPage + (currentPage % 5);

            this.currentPage = targetPage > this.pageCount ? this.pageCount : targetPage;
            this.$emit('onPageChange');
        },
        clickPageNum(index) {
            this.currentPage = index - 1;
            this.$emit('onPageChange');
        },
    }
}
</script>

<style scoped>

</style>